@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply text-cod-gray;
    @apply font-montserrat;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.backdrop {
  background-color: rgb(0, 0, 0, 0.5);
}

.fc-day-today {
  background-color: #ffffff !important;
}

.fc-button-primary {
  background-color: #3968ca !important;
  border-color: #6938ca !important;
}

.fc-button {
  box-shadow: none !important;
}

.fc-button {
  border: 0px !important;
}

.fc-event-title {
  padding: 0px !important;
}

.fc-event-title-container {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  min-width: 0 !important;
}

.event-title-cancel .fc-event-title.fc-sticky{
  color: #EB6565;
}

.event-title-complete .fc-event-title.fc-sticky{
  color: #65A30D;
}

.event-title-inprogress .fc-event-title.fc-sticky{
  color: #3968CA;
}

.circle {
  display: inline-block;
    background-color: #F7F7F7;
    border-radius: 50%;
}

.circle-inner {
  color: #3968CA;
  display: table-cell;
  vertical-align: middle; 
  text-align: center;
  text-decoration: none;
  height: 40px;
  width: 40px;  
  font-size: 14px;
  font-weight: bolder;
}

.location_suggestion_pickup {
  position: absolute;
  background: white;
  backdrop-filter: blur(14.5px);
  -webkit-backdrop-filter: blur(14.5px);
  padding: 10px;
}

.location_suggestion_pickup ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.location_suggestion_pickup ul li strong {
  font-family: var(--header-text);
  color: var(--brand-blue);
  font-weight: 500;
}

.location_suggestion_pickup ul li small {
  font-family: var(--paragraph-text);
  color: var(--black);
  font-weight: 500;
}