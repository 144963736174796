.outer-container {
    min-height: calc(100vh - 65px);
    width: 100%;
    max-width: 100%;
}
.inner-container {
    width: fit-content;
    height: min-content;
}

.inner-container-NMS {
    width: fit-content;
    margin: 0.5%;
    height: min-content;
}