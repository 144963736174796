.switch-outer {
  background-color: #3968ca;
}
.switch-outer.disable {
    background-color: white;
    border: 1px solid lightgray;
  }
.switch-inner {
    background-color: white;
}
.switch-inner.disable {
    background-color: lightgray;
  }
